import { connect } from "react-redux";
import { compose } from "redux";
import Loading from "./Loading";

const withLoading = (Component) => (props) => {
  const { portfolio } = props;
  if (!portfolio.isLoaded) {
    return <Loading {...props} />;
  }

  return <Component {...props} />;
};

const mapStateToProps = (state) => ({
  portfolio: state.portfolio,
});

export default compose(connect(mapStateToProps, null), withLoading);

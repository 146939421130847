import Link from "next/link";

const Keyword = ({ type, value, displayText, isSelected }) => {
  const css = isSelected ? "badge-secondary" : "badge-primary";
  return (
    <Link href={{ pathname: "/search", query: { query: `${type}:${value}` } }}>
      <a className={`badge ${css}`}>{displayText || value}</a>
    </Link>
  );
};

export default Keyword;

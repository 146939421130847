import React, { useEffect } from "react";
import { connect } from "react-redux";
import Link from "next/link";
import Layout from "components/Layout";
import Keyword from "components/Keyword";
import Tooltip from "components/Tooltip";
import withAuth from "components/withAuth";
import withLoading from "components/withLoading";
import { builder } from "utils/funds/builder";
import styles from "./index.module.scss";

export async function getStaticProps() {
  const keywords = require("data/keywords.json");
  const sectors = require("data/sectors.json");
  const operators = require("data/operators.json");
  return { props: { keywords, sectors, operators } };
}

const KeywordsCard = ({ items, portfolio }) => {
  const counts = {};

  portfolio.forEach((item) => {
    item.keywords.forEach((keyword) => {
      const count = counts[keyword] || 0;
      counts[keyword] = count + 1;
    });
  });

  return (
    <div className="keywords">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className="card p-4">
            <div className="pb-2">
              <a className="anchor" id="keywords"></a>
              <h4 tabIndex={0}>
                Keywords{" "}
                <Tooltip tip="Each fund has been broken down into digestible words. Clicking on a keyword shows all funds that match the keyword." />
              </h4>
            </div>
            <div className="tags">
              {items.map((item) => (
                <Keyword
                  type="keyword"
                  key={item.name}
                  value={item.name}
                  displayText={`${item.name} [${counts[item.name] || "0"}]`}
                  isSelected={portfolio.some((pItem) =>
                    pItem.keywords.some(
                      (keyword) =>
                        keyword.toLowerCase() === item.name.toLowerCase()
                    )
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectorsCard = ({ items, portfolio }) => {
  const counts = portfolio.reduce((acc, item) => {
    acc[item.sector] = acc[item.sector] ? acc[item.sector] + 1 : 1;
    return acc;
  }, Object.create(null));

  return (
    <div className="sectors">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className="card p-4">
            <div className="pb-2">
              <a className="anchor" id="sectors"></a>
              <h4 tabIndex={0}>
                Sectors{" "}
                <Tooltip tip="Each fund has been categorised into a sector. Clicking a sector reveals all those that are in the same sector." />
              </h4>
            </div>
            <div className="tags">
              {items.map((item) => (
                <Keyword
                  type="sector"
                  key={item.name}
                  value={item.name}
                  displayText={`${item.name} [${counts[item.name] || "0"}]`}
                  isSelected={portfolio.some(
                    (pItem) =>
                      pItem.sector.toLowerCase() === item.name.toLowerCase()
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OperatorsCard = ({ items, portfolio }) => {
  const counts = portfolio.reduce((acc, item) => {
    acc[item.operator] = acc[item.operator] ? acc[item.operator] + 1 : 1;
    return acc;
  }, Object.create(null));

  return (
    <div className="operators">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className="card p-4">
            <div className="pb-2">
              <a className="anchor" id="operators"></a>
              <h4 tabIndex={0}>
                Operators{" "}
                <Tooltip tip="All these operators that are offering funds." />
              </h4>
            </div>
            <div className="tags">
              {items.map((item) => (
                <Keyword
                  type="operator"
                  key={item.name}
                  value={item.name}
                  displayText={`${item.name} [${counts[item.name] || "0"}]`}
                  isSelected={portfolio.some(
                    (pItem) =>
                      pItem.operator.toLowerCase() === item.name.toLowerCase()
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IncomeFundsCard = ({ items }) => {
  const numOfFunds = items.filter((item) => item.stockType === "INCOME").length;

  return (
    <div className="card text-center">
      <div className="card-body">
        <h3 className="card-title bold-text">{numOfFunds}</h3>
        <p className="card-text">Income funds</p>
      </div>
    </div>
  );
};

const GrowthFundsCard = ({ items }) => {
  const numOfFunds = items.filter((item) => item.stockType === "GROWTH").length;

  return (
    <div className="card text-center">
      <div className="card-body">
        <h3 className="card-title bold-text">{numOfFunds}</h3>
        <p className="card-text">Growth funds</p>
      </div>
    </div>
  );
};

const FundsInvestedCard = ({ items }) => {
  const numOfFunds = items.length;

  return (
    <div className="card text-center">
      <div className="card-body">
        <h3 className="card-title bold-text">{numOfFunds}</h3>
        <p className="card-text">Funds invested</p>
      </div>
    </div>
  );
};

const AverageRiskCard = ({ items }) => {
  return (
    <div className="card text-center">
      <div className="card-body">
        <h3 className="card-title bold-text">TODO</h3>
        <p className="card-text">Average Risk</p>
      </div>
    </div>
  );
};

const DashboardDetails = ({ keywords, sectors, operators, portfolio }) => {
  const { data } = portfolio;

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="card-columns mb-4">
        <FundsInvestedCard items={data} />
        <GrowthFundsCard items={data} />
        <IncomeFundsCard items={data} />
        <AverageRiskCard items={data} />
      </div>
      <div className="row">
        <div className="col">
          <KeywordsCard items={keywords} portfolio={data} />
          <SectorsCard items={sectors} portfolio={data} />
          <OperatorsCard items={operators} portfolio={data} />
        </div>
      </div>
    </div>
  );
};

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="card">
        <div className="card-body text-center">
          <h2 className="card-title bold-text mb-4">Welcome</h2>
          <div className="card-text">
            <p className="mb-4">
              Welcome to Fundlytics where we simplify the research so you can
              quickly analyse and make better investment decisions. As you build
              your portfolio you&apos;ll begin to see what and where you&apos;ve
              invested and most importantly gaps in your investment portfolio.
            </p>
            <p className="mb-4">
              This research tool is unlike any other, you can compare similar
              funds and we&apos;ll break down the financial terminology into
              something easy to understand.
            </p>
            <p className="mb-4">
              Head over to the{" "}
              <Link href="/search">
                <a>search</a>
              </Link>{" "}
              page and add your first fund.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = ({ keywords, sectors, operators, portfolio }) => {
  return (
    <Layout>
      <div className={styles.home}>
        {portfolio.data.length === 0 ? (
          <Welcome />
        ) : (
          <DashboardDetails
            keywords={keywords}
            sectors={sectors}
            operators={operators}
            portfolio={portfolio}
          />
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  portfolio: state.portfolio,
});

export default withAuth(withLoading(connect(mapStateToProps, null)(Dashboard)));

import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { isClientSide } from "utils/environment";
import styles from "./Loading.module.scss";

const Loading = ({ fetchData }) => {
  if (isClientSide) {
    fetchData();
  }

  return (
    <div className={styles.loading}>
      <ReactLoading type="bars" color="#00132a" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  portfolio: state.portfolio,
});

const mapDispatchToProps = ({ portfolio: { fetch } }) => ({
  fetchData: fetch,
});

export default connect(null, mapDispatchToProps)(Loading);
